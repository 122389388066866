<template>
  <div class='page-login'>

      <div class='container'  v-on:keyup.shift.alt.68="onAdminMode">
        <div class="card">
          <ul v-if='errors' class='error-messages'>
            <li v-for='(v, k) in errors' :key='k'>{{ v }}</li>
          </ul>
          <form @submit.prevent='onSubmit()'>
            <h1>{{ $t('login.title') }}</h1>
            <fieldset>

              <div class="item">
                <label for="l">{{ $t('login.username') }}</label>
                <input ref="login" id="l" type='text' v-model='username'/>
              </div>

              <div class="item">
                <label for="p">{{ $t('login.password') }}</label>
                <input id="p" type='password' v-model='password' />
              </div>

              <div class="item">
                <label for="p">{{ $t('login.expo_code')}}</label>
                <input id="e" type='text' v-model='expo_code'/>
              </div>

              <div class="item admin" v-if="showAdvanced">
                <label for="p">Accès restreint</label>
                <input id="r" ref="restrict" type='password' v-model='admin_jugement'/>
              </div>

            </fieldset>
            <div class="actions">
              <button class='btn action login' :disabled="!isFilled" type="submit">
                {{ $t('login.action_login') }}
              </button>
              <button class='btn action reset' @click="onReset()" type="reset">
                {{ $t('login.action_reset') }}
              </button>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { LOGIN, LOGIN_RESET, FETCH_EXPO } from '@/store/const/actions.type';

import i18n from '../i18n';

export default {
  name: 'Login',
  mounted() {
    if (!i18n.locale) { // TODO : find better option
      i18n.locale = 'fr';
    }
    const urlParams = new URLSearchParams(window.location.search);
    this.username = urlParams.get('id') ?? '';
    this.password = urlParams.get('pwd') ?? '';
    this.expo_code = urlParams.get('code') ?? '';
    this.admin_jugement = urlParams.get('adm') ?? '';

    this.$nextTick(() => {
      this.focusLoginField();
    });
  },
  data() {
    return {
      username: null,
      password: null,
      expo_code: null,
      admin_jugement: null,
      showAdvanced: false,
    };
  },
  methods: {
    onAdminMode() {
      this.showAdvanced = !this.showAdvanced;
      this.$nextTick(() => {
        this.focusRestrictField();
      });
    },
    focusLoginField() {
      this.$refs.login.focus();
    },
    focusRestrictField() {
      this.$refs.restrict.focus();
    },
    onSubmit() {
      // console.log('onsubmit ?');
      const loginParams = {
        username: this.username,
        password: this.password,
        expo: this.expo_code,
      };
      if (this.admin_jugement) {
        loginParams.admin_jugement = this.admin_jugement;
      }
      this.$store.dispatch(LOGIN, loginParams)
        .then(() => this.$store.dispatch(FETCH_EXPO))
        .then(() => this.$router.push({ name: 'home' }));
    },
    onReset() {
      this.$store.dispatch(LOGIN_RESET);
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    isFilled() {
      return this.username && this.password && this.expo_code;
    },
  },
};
</script>
<style lang="scss">
  .page-login {
    .container {
      @media (min-width: 768px) {
        height: 50vh;
      }
      display: flex; align-items: center; justify-items: center }
    fieldset {border:0}
    label {font-weight: 500; color: #494949}
    input { width: 100%; padding: .5rem .5rem .5rem 0;
      margin-bottom: 1rem; border: 0; background-color: transparent;
      border-bottom: 1px #AAA solid; box-shadow:none;
    }
    .admin label {color: #1d8fa1}
    .admin input {background: #d3f1f5}
    .error-messages { color: #b85c5c !important; font-weight: 700; margin-bottom: 1rem;
      text-align: center}

    .actions {display: flex; justify-content: space-around; flex-direction: row-reverse;}
  }
</style>
